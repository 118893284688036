<template>
  <div class="be-card h-100">
    <div class="position-relative">
      <header-with-divider
        :color="smallSize ? 'blue center' : 'blue'"
        :text="$t('withdraw.withdraw_list')"
      />
    </div>
    <div
      class="align-items-center justify-content-between p-sm-4 pb-4 pt-4 pl-3 pr-3 d-flex"
    >
      <div class="card-header-right">
        <BaseInputSearch :api-search="true" @search="search" />
      </div>
      <div>
        <button
          :class="{
            'i-left': !smallSize,
            'circle-btn': smallSize,
          }"
          class="be-btn primary green lg ml-2"
          @click="$router.push({ name: 'withdraw.form' })"
        >
          <i class="icon-plus" />
          {{ !smallSize ? $t("withdraw.new_withdraw") : "" }}
        </button>
      </div>
    </div>
    <div class="divider d-none d-sm-flex" />
    <loading v-if="loading" />
    <div
      v-else-if="!loading && withdraws && withdraws.length"
      class="scroll-wrapper"
    >
      <be-table
        :data="withdraws"
        :fields="smallSize ? smallTableFields : fields"
        :smallSize="smallSize"
        rowClasses="py-3 mh-40"
      >
        <template v-slot:type="{ row }">
          <div class="d-flex">
            <i
              :class="[operationIcon(row.type), operationClass(row.type)]"
              class="op-icon"
            />
            <span>
              {{ row.status }}
            </span>
          </div>
        </template>
        <template v-slot:status="{ row }">
          <span
            :class="[
              smallSize
                ? statusColorClass(row.status)
                : statusClass(row.status),
              smallSize ? '' : 'status',
            ]"
          >
            {{ $t(`status.${row.status.toLowerCase()}`) }}
          </span>
        </template>
        <template v-slot:amount="{ row }">
          <div class="d-flex flex-column f-w-600">
            {{ row.amount + " " + row.currency }}
            <small
              v-if="!smallSize"
              :class="statusColorClass(row.status)"
              class="f-w-600"
            >
              {{ $t(`status.${row.status.toLowerCase()}`) }}
            </small>
          </div>
        </template>
        <template v-slot:fees="{ row }">
          <div>
            <div class="d-flex flex-column">
              <span class="f-w-600">
                {{ row.fee_percent }}%
                <span v-if="smallSize"
                  >- {{ row.fee_amount + " " + row.currency }}</span
                >
              </span>
              <small v-if="!smallSize" class="op-8">
                {{ $t("utils.fees") }}:
                {{ row.fee_amount + " " + row.currency }}
              </small>
            </div>
          </div>
        </template>

        <template v-slot:operator="{ row }">
          <div class="d-flex flex-column">
            <img
              :src="row.operator && row.operator.logo_url"
              :alt="(row.operator && row.operator.label) || ''"
              width="70"
            />
          </div>
        </template>
        <template v-slot:created_at="{ row }">
          <div v-if="smallSize">
            {{ row.created_at | moment("Do MMMM YYYY H:mm:ss") }}
          </div>
          <div v-else class="d-flex flex-column">
            <span>{{ row.created_at | moment("Do MMMM YYYY") }}</span>
            <small>{{ row.created_at | moment("H:mm:ss") }}</small>
          </div>
        </template>
        <template #transaction_id="{ row }">
          <div class="d-flex flex-column">
            {{ row.transaction_id }}
          </div>
        </template>
        <template v-slot:actions="{ row }">
          <div @click.stop="openModal(row)">
            <i :class="smallSize && 'd-block'" class="icon-arrow-right" />
          </div>
        </template>
      </be-table>
      <b-modal ref="detailsModal" no-fade size="lg" top>
        <div class="text-center" v-if="modalData">
          <b-row>
            <b-col>
              <div class="label-text">
                <div>{{ $t("common.operator") }}</div>
                <div>
                  {{ modalData.operator.label || $t("common.correction") }}
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="label-text">
                <div>{{ $t("common.phone_number") }}</div>
                <div>{{ modalData.service_number.svc_number }}</div>
              </div>
            </b-col>
            <b-col>
              <div class="label-text">
                <div>{{ $t("common.withdraw_amount") }}</div>
                <div>
                  {{ `${modalData.amount} ${modalData.currency}` }}
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col>
              <div class="label-text">
                <div>{{ $t("common.status") }}</div>
                <div :class="statusColorClass(modalData.status)">
                  {{ $t(`status.${modalData.status.toLowerCase()}`) }}
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="label-text">
                <div>{{ $t("common.creation_date") }}</div>
                <div>
                  {{ modalData.created_at | moment("Do MMMM YYYY H:mm:ss") }}
                </div>
              </div>
            </b-col>
            <b-col />
          </b-row>
        </div>
        <template slot="modal-header">
          <span />
          <span class="modal-title">
            {{ $t("withdraw.withdraw_details") }} -
            {{ modalData && modalData.transaction_id }}
          </span>
          <span @click="hideModal">
            <i class="icon-close f-24" />
          </span>
        </template>
        <template slot="modal-footer">
          <template
            v-if="
              modalData &&
                (modalData.status === 'Echec' ||
                  modalData.status === 'En cours')
            "
          >
            <span class="f-w-600 fermer mr-4" @click="hideModal">
              {{ $t("common.close") }}
            </span>
            <button
              class="ml-4 be-btn primary lg danger"
              @click="$router.push({ name: 'assistance' })"
            >
              {{ $t("common.create_a_ticket") }}
            </button>
          </template>
          <button v-else class="ml-4 be-btn primary lg blue" @click="hideModal">
            {{ $t("common.close") }}
          </button>
        </template>
      </b-modal>
      <BasePagination
        :current_page="current_page"
        :last_page="last_page"
        @page="changePage"
      />
    </div>
    <no-content
      v-else
      :text="$t('withdraw.no_withdraw_done')"
      icon="icon-withdraw"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import BeTable from "@/components/BeTable";
import Loading from "@/components/common/Loading";
import NoContent from "@/components/common/NoContent";
import HeaderWithDivider from "@/components/common/HeaderWithDivider";
import { OperationClassMixin } from "@/mixins/OperationClassMixin";
import WithdrawService from "@/services/WithdrawService";

export default {
  name: "WithdrawList",
  mixins: [OperationClassMixin],
  components: { BeTable, HeaderWithDivider, Loading, NoContent },
  data() {
    return {
      loading: true,
      modalData: null,
      withdrawField: {
        page: 1,
        keyword: null,
      },
      smallTableFields: [
        {
          label: this.$t("common.status"),
          key: "status",
        },
        {
          label: this.$t("common.date"),
          key: "created_at",
        },
        {
          label: this.$t("common.amount"),
          key: "amount",
        },
        {
          label: this.$t("common.fees"),
          key: "fees",
        },
        {
          label: this.$t("common.operator"),
          key: "operator",
        },
        {
          label: this.$t("common.reference"),
          key: "transaction_id",
        },
        {
          label: "",
          key: "actions",
        },
      ],
      fields: [
        {
          label: this.$t("common.status").toUpperCase(),
          key: "status",
        },
        {
          label: this.$t("common.date").toUpperCase(),
          key: "created_at",
        },
        {
          label: this.$t("common.amount").toUpperCase(),
          key: "amount",
        },
        {
          label: this.$t("common.fees").toUpperCase(),
          key: "fees",
        },
        {
          label: this.$t("common.operator").toUpperCase(),
          key: "operator",
        },
        {
          label: this.$t("common.reference").toUpperCase(),
          key: "transaction_id",
        },
        {
          label: "",
          key: "actions",
          class: "col-1 d-flex align-items-center justify-content-end",
        },
      ],
    };
  },

  computed: {
    ...mapGetters("withdraw", ["withdraws", "current_page", "last_page"]),
    ...mapState({
      sidebarState: state => state.sidebarState,
    }),
    smallSize() {
      return this.$screen.width - (this.sidebarState ? 320 : 100) < 992;
    },
  },

  created() {
    this.getWithdrawList();
  },

  methods: {
    getWithdrawList() {
      this.loading = true;
      this.$store
        .dispatch("withdraw/getWithdrawList", this.withdrawField)
        .finally(() => (this.loading = false));
    },
    openModal(row) {
      this.modalData = row;
      this.$refs.detailsModal.show();
    },
    hideModal() {
      this.$refs.detailsModal.hide();
    },
    search(text) {
      this.withdrawField.keyword = text;
      this.getWithdrawList();
    },
    changePage(page) {
      this.withdrawField.page = page;
      this.getWithdrawList();
    },
  },
};
</script>
<style scoped>
.phone {
  display: inline-block;
  max-width: 155px;
}

.modal-title {
  color: #0b1527;
  font-weight: 600;
}

.icon-close {
  cursor: pointer;
}

.fermer {
  cursor: pointer;
}

/deep/ .modal-backdrop {
  background: rgba(255, 255, 255, 0.2) !important;
  backdrop-filter: blur(3px) !important;
}
</style>
